<template>
    <custom-table
        :title="$t('pages.module.examManagement.exam.session.title')"
        :subTitle="$t('pages.module.examManagement.exam.session.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:sessionDate="{ row: record }">
            {{ $moment(record.session_date).format("DD.MM.YYYY") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.examManagement.exam.session.user.title')" placement="top">
                    <router-link :to="'/module/exam-management/exam/session/user?sessionID=' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <a v-on:click="fetchSession(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_add_session" ref="addSessionModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="sessionForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.session.cols.sessionDate') }}</label>
                                <el-form-item prop="session_date" :rules="$validation.getMessage(['required'])">
                                    <el-date-picker v-model="form.data.session_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledDate"></el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.session.cols.sessionTime') }}</label>
                                <el-form-item prop="session_time" :rules="$validation.getMessage(['required'])">
                                    <el-time-picker v-model="form.data.session_time" :placeholder="$t('common.chooseTime')" valueFormat="HH:mm" format="HH:mm"></el-time-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.session.cols.quota') }}</label>
                                <el-form-item prop="quota" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.quota" :min="1" :max="100"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.session.cols.fakeQuota') }}</label>
                                <el-form-item prop="fake_quota">
                                    <el-input-number v-model="form.data.fake_quota" :min="0"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    props: ['examID'],
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.cols.sessionDate"),
                    scopedSlots: {customRender: "sessionDate"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.cols.sessionTime"),
                    key: "session_time"
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.cols.quota"),
                    key: "quota"
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.cols.fakeQuota"),
                    key: "fake_quota"
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.cols.realRemainingQuota"),
                    key: "real_remaining_quota"
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            table: {
                data: [],
                loading: false
            },
            exam: {},
        }
    },
    computed: {
        actions() {
            let actions = [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ];

            if (this.table.data.length < 10) {
                actions.unshift({
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                });
            }

            return actions;
        },
    },

    mounted() {
        this.loadExam();
    },
    methods: {
        loadExam() {
            this.table.loading = true;

            this.axios.get(this.endpoints['module_exam'] + '/' + this.examID).then((response) => {
                let data = response.data.data;
                this.exam = data;
                this.table.data = data.sessions.sort((a,b) => {
                    let dateA =  this.$moment(a.session_date + ' ' + a.session_time);
                    let dateB =  this.$moment(b.session_date + ' ' + b.session_time);

                    return dateA - dateB;
                });
            }).finally(() => {
                this.table.loading = false;
            });
        },
        newSession() {
            this.form.updateStatus = false;
            this.form.data = {
                exam_id: this.examID,
                quota: 100,
                fake_quota: 0
            };
            this.form.title = this.$t("pages.module.examManagement.exam.session.newSession");
            this.showModal(this.$refs.addSessionModal);
        },
        fetchSession(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.module.examManagement.exam.session.editSession");
            this.axios.get(this.endpoints['module_exam_session'] + '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.showModal(this.$refs.addSessionModal);
            });
        },
        onSubmit() {
            this.$refs.sessionForm.validate((valid) => {
                if (valid) {
                    if(!this.checkSessionData()){
                        return;
                    }

                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_exam_session'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadExam();
                                this.hideModal(this.$refs.addSessionModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_exam_session'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadExam();
                                this.hideModal(this.$refs.addSessionModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("module/exam/session/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if (successDeleted) {
                    this.loadExam();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadExam();
                    break;

                case "new":
                    this.newSession();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        disabledDate(date){
            let examDate = this.$moment(this.exam.exam_date);

            return date.getTime() < examDate.valueOf() || date.getTime() > (examDate.valueOf() + 86400000) ;
        },
        checkSessionData(){
            try {
                let formData = this.form.data;

                let sessions = [];
                this.table.data.forEach((session) => {
                    if(session.id != this.form.data.id){
                        sessions.push(this.$moment(session.session_date + ' ' + session.session_time).valueOf());
                    }
                });

                sessions = sessions.sort();

                let addSession = this.$moment(formData.session_date + ' ' + formData.session_time).valueOf();
                let examDuration = this.exam.exam_duration * 60000;

                for(let i = 0; i < sessions.length; i++){
                    if(Math.abs(sessions[i] - addSession) < examDuration){
                        throw 'exam_duration';
                    }
                }

                return true;
            } catch (err){
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.module.examManagement.exam.session.warnings." + err),
                })
                return false;
            }
        }
    }
}
</script>

<style scoped>

</style>