<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="examID">
                {{ sprintf($t('pages.module.examManagement.exam.save.editExam'), [exam.translate.title ?? '']) }}
            </template>
            <template v-else>{{ $t('pages.module.examManagement.exam.save.newExam') }}</template>
        </h2>

        <div class="d-flex flex-wrap my-1">
            <router-link v-if="!$root.filterWithUrl" to="/module/exam-management/exam" class="btn btn-primary align-self-center">
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
                </span>
                {{ $t("pages.module.examManagement.exam.title") }}
            </router-link>
            <button class="btn btn-danger align-self-center ms-3" v-on:click="cloneQuestion" v-if="tabModel == 'questionRelationSettings'">
                <span class="svg-icon svg-icon-1">
                    <inline-svg src="/media/icons/duotune/general/gen028.svg" />
                </span>
                {{ $t("pages.module.examManagement.exam.cloneQuestion.title") }}
            </button>
        </div>
    </div>

    <el-tabs v-model="tabModel" class="exam-tabs">
        <el-tab-pane :label="$t('pages.module.examManagement.exam.save.tabs.generalDetails')" name="general">
            <el-form :model="form" ref="examForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.cols.title') }}</label>
                                    <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.translate.title" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.description') }}</label>
                                    <el-form-item prop="translate.description">
                                        <el-input v-model="form.translate.description" type="textarea" rows="10" maxlength="512"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.category') }}</label>
                                    <el-form-item prop="data.category_id" :rules="$validation.getMessage(['required'])">
                                        <el-input type="hidden" v-model="form.data.category_id"></el-input>
                                        <el-select-tree :value="form.data.category_id" @input="form.data.category_id = $event" :data="treeCategories" :multiple="false" :placeholder="$t('common.chooseSelect')"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.templateType') }}</label>
                                    <el-form-item prop="data.template_type_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.template_type_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(templateType, templateTypeIndex) in templateTypes" :key="templateTypeIndex" :value="templateType.id" :label="templateType.name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.questionType') }}</label>
                                    <el-form-item prop="data.question_type_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.question_type_id" class="w-100" :placeholder="$t('common.chooseSelect')" :disabled="exam.questions_count > 0">
                                            <el-option v-for="(questionType, questionTypeIndex) in questionTypes" :key="questionTypeIndex" :value="questionType.id" :label="questionType.name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1" v-if="!exam.without_session">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.cols.examDate') }}</label>
                                    <el-form-item prop="data.exam_date" :rules="$validation.getMessage(['required'])">
                                        <el-date-picker v-model="form.data.exam_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledDate"></el-date-picker>
                                    </el-form-item>
                                </div>


                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.usageMinDate') }}</label>
                                    <el-form-item prop="data.usage_min_date">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="form.data.usage_min_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="disabledUsageMinDate"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.module.examManagement.exam.save.cols.usageMaxDate') }}
                                        <el-tooltip class="item" effect="dark" popper-class="max-w-300px" :content="$t('pages.module.examManagement.exam.save.informationBoxes.usageMaxDate')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                            </span>
                                        </el-tooltip>
                                    </label>
                                    <el-form-item prop="data.usage_max_date">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="form.data.usage_max_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="disabledUsageMaxDate"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.cols.examDuration') }}</label>
                                    <el-form-item prop="data.exam_duration" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.exam_duration" :min="1"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.cols.questionCount') }}</label>
                                    <el-form-item prop="data.question_count" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.question_count" :min="exam.questions_count || 1"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.module.examManagement.exam.save.cols.deductedWrong') }}

                                        <el-tooltip class="item" effect="dark" popper-class="max-w-300px" :content="$t('pages.module.examManagement.exam.save.informationBoxes.deductedWrong')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                            </span>
                                        </el-tooltip>
                                    </label>
                                    <el-form-item prop="data.deducted_wrong" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.deducted_wrong" :min="0" :max="5"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.withoutSession') }}</label>
                                    <el-form-item prop="data.without_session">
                                        <el-radio-group v-model="form.data.without_session" :disabled="form.updateStatus">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1" v-if="form.data.without_session">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.usageType') }}</label>
                                    <el-form-item prop="data.usage_type_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.usage_type_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                                            <el-option :value="1" :label="$t('pages.module.examManagement.exam.save.usageType.everyone')"></el-option>
                                            <el-option :value="2" :label="$t('pages.module.examManagement.exam.save.usageType.ownProduct')"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1" v-if="form.data.usage_type_id == 2">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.save.cols.products') }}</label>
                                    <el-form-item prop="data.product_relations">
                                        <el-select v-model="form.data.product_relations" class="w-100" :placeholder="$t('common.chooseSelect')" filterable multiple>
                                            <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                    <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.sort" :min="1" />
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                    <el-form-item prop="data.active">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && !exam.without_session" :label="$t('pages.module.examManagement.exam.save.tabs.sessionSettings')" name="sessionSettings">
            <ExamSession :examID="examID" v-if="tabModel == 'sessionSettings'"></ExamSession>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.examManagement.exam.save.tabs.questionRelationSettings')" name="questionRelationSettings">
            <ExamQuestionRelation :examID="examID" v-if="tabModel == 'questionRelationSettings'"></ExamQuestionRelation>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";
import ExamSession from "@/components/module/exam/session";
import ExamQuestionRelation from "@/components/module/exam/question-relation";

export default {
    name: "_id",
    components: {
        ElSelectTree,
        ExamSession,
        ExamQuestionRelation
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id
                },
                data: {
                    active: true,
                    without_session: false,
                    question_count: 100,
                    exam_duration: 120,
                    deducted_wrong: 0,
                    usage_type_id: 1,
                    product_relations: [],
                    sort: 1
                }
            },
            exam: {
                translate: {}
            },
            treeCategories: [],
        }
    },
    computed: {
        examID() {
            return this.$route.params.id;
        },
        templateTypes() {
            return this.$store.state.module.exam.template.type.table.data;
        },
        questionTypes() {
            return this.$store.state.module.exam.question.type.table.data;
        },
        products() {
            let products = this.$store.state.ecommerce.productManagement.product.table.data.map((product) => {
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id')

                return product;
            })

            return products;
        },
    },
    created() {
        if (this.examID && !(this.examID > 0)) {
            this.$router.push({
                path: "/module/exam-management/exam"
            });
        }
    },
    mounted() {
        if (this.examID && this.examID > 0) {
            this.loadExam();
        }

        this.loadTreeCategories();
        this.$store.dispatch('module/exam/template/type/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('module/exam/question/type/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('ecommerce/productManagement/product/get', {
            page: {pageSize: 9999}
        });
    },
    methods: {
        loadExam(examID = this.examID) {
            this.axios.get(this.endpoints['module_exam'] + '/' + examID).then((response) => {
                let data = response.data.data;

                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                data.usage_min_date = data.usage_min_date ? this.$moment(data.usage_min_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.usage_max_date = data.usage_max_date ? this.$moment(data.usage_max_date).format('YYYY-MM-DD HH:mm:ss') : undefined;


                this.exam = data;
                this.form.translate = data.translate;

                this.form.updateStatus = true;
                this.form.data = data;
            })
        },
        loadTreeCategories() {
            this.axios.get(this.endpoints['module_exam_category'] + '/tree').then((response) => {
                this.treeCategories = response.data.data;
            });
        },
        onSubmit() {
            this.$refs.examForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_exam'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadExam();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_exam'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                let examID = response.data.data.id;
                                this.$router.push({
                                    path: "/module/exam-management/exam/save/" + examID
                                });
                                this.loadExam(examID);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = this.form.data;

            if(formData.without_session){
                formData.exam_date = this.$moment().format('YYYY-MM-DD');
            } else {
                formData.usage_type_id = 1;
                delete formData.product_relations;
            }

            return {...this.form.translate, ...formData}
        },
        disabledDate(date){
            date = this.$moment(date);
            return this.$moment().add(-1, 'days').valueOf() > date.valueOf();
        },
        cloneQuestion(){
            this.eventBus.$emit('cloneQuestion');
        },
        disabledUsageMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.usage_max_date ? this.$moment(this.form.data.usage_max_date) : undefined;

            return this.$moment().add(-1, 'days').valueOf() > startDate.valueOf() || (endDate != undefined && startDate.valueOf() > endDate.valueOf());
        },
        disabledUsageMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.usage_min_date ? this.$moment(this.$moment(this.form.data.usage_min_date).format('YYYY-MM-DD')) : this.$moment().add(-1, 'days');
            return startDate.valueOf() > endDate.valueOf();
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>