<template>
    <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h2 class="card-title fw-bolder">{{ $t('pages.module.examManagement.exam.questionRelation.questionFilter.title') }}</h2>
                </div>
                <div class="card-body d-flex flex-column p-9">
                    <div class="fv-row mb-7">
                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.questionRelation.questionFilter.cols.questionType') }}</label>
                        <div>
                            <el-select v-model="questionFilterData.type_id" class="w-100" :placeholder="$t('common.chooseSelect')" disabled>
                                <el-option v-for="(type, typeIndex) in questionTypes" :key="typeIndex" :value="type.id" :label="type.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.questionRelation.questionFilter.cols.category') }}</label>
                        <div>
                            <el-input type="hidden" v-model="questionFilterData.category_id"></el-input>
                            <el-select-tree :value="questionFilterData.category_id" @input="questionFilterData.category_id = $event" :data="questionTreeCategories" :multiple="false" :placeholder="$t('common.chooseSelect')"/>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.questionRelation.questionFilter.cols.magic') }}</label>
                        <div>
                            <el-input v-model="questionFilterData.magic" type="text"/>
                        </div>
                    </div>

                    <div class="fv-row">
                        <label class="form-label fw-bold">{{ $t('pages.module.examManagement.exam.questionRelation.questionFilter.cols.gain') }}:</label>
                        <div>
                            <el-select v-model="gainModel" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple @change="changedGain">
                                <el-option v-for="(gain, gainIndex) in gains" :key="gainIndex" :value="gain.id" :label="gain.translate.title"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="card-footer py-5">
                    <div class="d-flex justify-content-end">
                        <button type="reset" v-on:click="onResetQuestionFilter" class="btn btn-sm btn-white btn-active-light-primary me-2">{{ $t('btn.reset') }}</button>
                        <button type="submit" v-on:click="onQuestionFilter" class="btn btn-sm btn-primary">{{ $t('btn.apply') }}</button>
                    </div>
                </div>
            </div>

            <custom-table
                class="mt-7"
                :title="$t('pages.module.examManagement.question.title')"
                :subTitle="$t('pages.module.examManagement.question.subTitle')"
                rowKey="id"
                :items="questionTable.data"
                :loading="questionTable.loading"
                :columns="question.fields"
                :pagination="questionTable.pagination"
                @changeTable="handleQuestionTableChange">
                <template v-slot:content="{ row: record }">
                    <div v-html="resolveDatum(record.translations, $root.defaultLanguage.id, 'language_id').content" v-math></div>
                </template>
                <template v-slot:correctAnswer="{ row: record }">
                    <a v-if="record.type_id == 1 && record.correct_answer_id && record.answers.length" class="fw-bold text-gray-600 text-hover-primary cursor-pointer" v-on:click="showDetail('correctAnswerModal', record)">
                        {{ sprintf($t('pages.module.examManagement.question.answer.titlePattern'), [$root.questionLetters[getCorrectAnswer(record).foundIndex]]) }}
                    </a>
                    <span v-else>{{ getRecord('correctAnswer', record) ?? "-" }}</span>
                </template>
                <template v-slot:answerCount="{ row: record }">
                    {{ getRecord('answerCount', record) ?? "-" }}
                </template>
                <template v-slot:actions="{ row: record }">
                    <div class="d-flex justify-content-end">
                        <el-tooltip :content="$t('pages.module.examManagement.question.answer.title')" placement="top" v-if="record.answers && record.answers.length">
                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 cursor-pointer" v-on:click="showDetail('answerModal', record)">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                                </span>
                            </a>
                        </el-tooltip>
                        <el-tooltip v-if="!(record.pivot && record.pivot.id)" :content="$t('pages.module.examManagement.exam.questionRelation.addQuestion')" placement="top">
                            <a class="btn btn-icon btn-success btn-sm" v-on:click="onSubmitExamQuestion('add', record)">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                                </span>
                            </a>
                        </el-tooltip>
                        <el-popconfirm v-else :title="$t('pages.module.examManagement.exam.questionRelation.sureRemoveQuestion')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="onSubmitExamQuestion('remove', record)">
                            <template #reference>
                                <a class="btn btn-icon btn-danger btn-sm cursor-pointer">
                                    <span class="svg-icon svg-icon-3">
                                        <inline-svg src="/media/icons/duotune/arrows/arr089.svg" />
                                    </span>
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </custom-table>
        </div>
        <div class="col-md-6">
            <custom-table
                :runModeServer="false"
                :title="sprintf($t('pages.module.examManagement.exam.questionRelation.title'), [exam.translate.title ?? ''])"
                :subTitle="$t('pages.module.examManagement.exam.questionRelation.subTitle')"
                :items="exam.questions"
                :columns="examQuestion.fields"
                :pagination="examQuestion.pagination">
                <template v-slot:sort="{ row: record }">
                    <el-input-number v-model="examQuestion.list[record.id].pivot.sort" :min="1" size="small" />
                </template>
                <template v-slot:content="{ row: record }">
                    <div v-html="resolveDatum(record.translations, $root.defaultLanguage.id, 'language_id').content" v-math></div>
                </template>
                <template v-slot:correctAnswer="{ row: record }">
                    <a v-if="record.type_id == 1 && record.correct_answer_id && record.answers.length" class="fw-bold text-gray-600 text-hover-primary cursor-pointer" v-on:click="showDetail('correctAnswerModal', record)">
                        {{ sprintf($t('pages.module.examManagement.question.answer.titlePattern'), [$root.questionLetters[getCorrectAnswer(record).foundIndex]]) }}
                    </a>
                    <span v-else>{{ getRecord('correctAnswer', record) ?? "-" }}</span>
                </template>
                <template v-slot:answerCount="{ row: record }">
                    {{ getRecord('answerCount', record) ?? "-" }}
                </template>
                <template v-slot:actions="{ row: record }">
                    <div class="d-flex justify-content-end">
                        <el-tooltip :content="$t('pages.module.examManagement.question.answer.title')" placement="top" v-if="record.answers && record.answers.length">
                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 cursor-pointer" v-on:click="showDetail('answerModal', record)">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                                </span>
                            </a>
                        </el-tooltip>
                        <el-tooltip :content="$t('btn.save')" placement="top">
                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2" v-on:click="onSubmitExamQuestion('update', examQuestion.list[record.id])">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/arrows/arr086.svg" />
                                </span>
                            </a>
                        </el-tooltip>
                        <el-popconfirm :title="$t('pages.module.examManagement.exam.questionRelation.sureRemoveQuestion')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="onSubmitExamQuestion('remove', record)">
                            <template #reference>
                                <a class="btn btn-icon btn-danger btn-sm cursor-pointer">
                                    <span class="svg-icon svg-icon-3">
                                        <inline-svg src="/media/icons/duotune/arrows/arr089.svg" />
                                    </span>
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </custom-table>
        </div>
    </div>

    <div class="modal fade" id="kt_modal_correct_detail" ref="correctAnswerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_correct_detail_header">
                    <h2 class="fw-bolder">{{ $t("pages.module.examManagement.question.cols.correctAnswer") }}</h2>
                    <div id="kt_modal_correct_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_correct_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <template v-if="showDetailData && showDetailData.record">
                            <div class="fv-row detail-item" v-html="resolveDatum(showDetailData.record.translations, $root.defaultLanguage.id, 'language_id').content" v-math></div>
                        </template>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.correctAnswerModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_answer_detail" ref="answerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_answer_detail_header">
                    <h2 class="fw-bolder">{{ $t("pages.module.examManagement.question.answer.title") }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <template v-if="showDetailData">
                            <div class="fv-row detail-item" v-for="(answer, answerIndex) in showDetailData.answers" :key="answerIndex" :class="[answerIndex != 0 && 'mt-3']">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ sprintf($t('pages.module.examManagement.question.answer.titlePattern'), [$root.questionLetters[answerIndex]]) }}:</label>
                                <span class="text-gray-500 fw-bold fs-6" v-html="resolveDatum(answer.translations, $root.defaultLanguage.id, 'language_id').content" v-math></span>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.answerModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_clone_question" ref="cloneQuestionModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_clone_question_header">
                    <h2 class="fw-bolder">{{ cloneQuestion.form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitCloneQuestion()" :model="cloneQuestion.form.data" ref="cloneQuestionForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_clone_question_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.cloneQuestion.cols.source') }}</label>
                                <el-form-item prop="source_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="cloneQuestion.form.data.source_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                        <el-option v-for="(exam, examIndex) in questionSourceExams" :key="examIndex" :value="exam.id" :label="exam.translate.title"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.cloneQuestion.cols.cloneType') }}</label>
                                <el-form-item prop="clone_type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="cloneQuestion.form.data.clone_type" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(type, typeIndex) in cloneQuestion.types" :key="typeIndex" :value="type" :label="$t('pages.module.examManagement.exam.cloneQuestion.cloneType.' + type)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="cloneQuestion.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="cloneQuestion.form.loading">
                            <span v-if="!cloneQuestion.form.loading" class="indicator-label">{{ $t("pages.module.examManagement.exam.cloneQuestion.submitClone") }}</span>
                            <span v-if="cloneQuestion.form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "index",
    props: ['examID'],
    components: {
        ElSelectTree,
        CustomTable
    },
    data(){
        return {
            form: {
                updateStatus: false,
                loading: false,
                data: {}
            },
            examQuestion: {
                fields: [
                    {
                        name: this.$t("common.id"),
                        key: "id",
                        class: ""
                    },
                    {
                        name: this.$t("common.sort"),
                        scopedSlots: {customRender: "sort"}
                    },
                    {
                        name: this.$t("pages.module.examManagement.question.cols.content"),
                        scopedSlots: {customRender: "content"}
                    },
                    {
                        name: this.$t("pages.module.examManagement.question.cols.correctAnswer"),
                        scopedSlots: {customRender: "correctAnswer"}
                    },
                    {
                        name: this.$t("pages.module.examManagement.question.cols.answerCount"),
                        scopedSlots: {customRender: "answerCount"},
                        class: ""
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
                list: {},
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0
                }
            },
            question: {
                fields: [
                    {
                        name: this.$t("common.id"),
                        key: "id",
                        class: ""
                    },
                    {
                        name: this.$t("pages.module.examManagement.question.cols.content"),
                        scopedSlots: {customRender: "content"}
                    },
                    {
                        name: this.$t("pages.module.examManagement.question.cols.correctAnswer"),
                        scopedSlots: {customRender: "correctAnswer"}
                    },
                    {
                        name: this.$t("pages.module.examManagement.question.cols.answerCount"),
                        scopedSlots: {customRender: "answerCount"},
                        class: ""
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
            },
            questionFilterData: {},
            exam: {
                translate: {},
                questions: [],
            },
            questionTreeCategories: [],
            examQuestionRelationResponseMessages: {
                you_have_reached_max_question_adding_limit: "youHaveReachedMaxQuestionAddLimit",
            },
            firstLoad: false,
            showDetailData: null,
            cloneQuestion: {
                types: ['overwrite', 'flush'],
                form: {
                    title: '',
                    loading: false,
                    data: {}
                },
                responseMessages: {
                    questions_cannot_changed_while_exam_has_starter_or_is_about_to_begin: "questionCannotChangedWhileStartExam",
                },
            },
            gainModel: [],
        }
    },
    computed: {
        questionTable() {
            let table = this.cloneData(this.$store.state.module.exam.question.table);

            table.data = table.data.map((question) => {
                let found = this.exam.questions.filter((examQuestion) => examQuestion.id == question.id);

                if(found.length == 1) {
                    question.pivot = found[0].pivot;
                }

                return question;
            })

            return table;
        },
        questionTypes() {
            return this.$store.state.module.exam.question.type.table.data;
        },
        questionSourceExams() {
            let exams = [];

            if(this.exam.id) {
                this.$store.state.module.exam.table.data.forEach((exam) => {
                    exam.translate =  this.resolveDatum(exam.translations, this.$root.defaultLanguage.id, 'language_id');

                    if(exam.id != this.exam.id && exam.questions_count > 0 && exam.question_type_id == this.exam.question_type_id){
                        exams.push(exam);
                    }
                });
            }

            return exams;
        },

        gains() {
            return this.$store.state.module.exam.gain.table.data.map((gain) => {
                gain.translate =  this.resolveDatum(gain.translations, this.$root.defaultLanguage.id, 'language_id');

                return gain;
            });
        },
    },
    created() {
        if (!this.examID || !(this.examID > 0)) {
            this.$router.push({
                path: "/module/exam-management/exam"
            });
        }
    },
    mounted() {
        if (this.examID && this.examID > 0) {
            this.loadExam();
            this.loadQuestionTreeCategories();

            this.$store.dispatch('module/exam/question/type/get', {
                page: { pageSize: 9999 }
            });

            this.eventBus.$on('cloneQuestion', () => {
                this.newCloneQuestion();
            });

            this.$store.dispatch('module/exam/get', {
                page: { pageSize: 9999 }
            });

            this.$store.dispatch('module/exam/gain/get', {
                page: { pageSize: 9999 }
            });
        }
    },
    beforeUnmount() {
        this.eventBus.$off('cloneQuestion', undefined, true);
    },
    methods: {
        loadExam() {
            this.axios.get(this.endpoints['module_exam'] + '/' + this.examID).then((response) => {
                let data = response.data.data;
                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                this.exam = data;

                let examQuestions = {};
                data.questions.forEach((question) => {
                    examQuestions[question.id] = question;
                });

                this.examQuestion.list = examQuestions;
                this.examQuestion.pagination.total = data.questions.length;

                if(!this.firstLoad){
                    this.questionFilterData.type_id = data.question_type_id;
                    this.questionFilterData.include_answers = 1;
                    this.onQuestionFilter();
                    this.firstLoad = true;
                }
            })
        },
        loadQuestionTreeCategories() {
            this.axios.get(this.endpoints['module_exam_question_category'] + '/tree').then((response) => {
                this.questionTreeCategories = response.data.data;
            });
        },
        handleQuestionTableChange(pagination, filterData) {
            this.$store.dispatch("module/exam/question/get", {
                page: pagination,
                filterData: Object.assign(this.questionFilterData, filterData)
            });
        },
        onResetQuestionFilter(){
            this.questionFilterData = {
                type_id: this.exam.question_type_id,
                include_answers: 1
            };

            this.gainModel = [];
            this.onQuestionFilter();
        },
        onQuestionFilter(){
            this.$store.dispatch('module/exam/question/get', {
                page: {},
                filterData: this.questionFilterData
            });
        },
        onSubmitExamQuestion(type, record){
            let method = type + 'ExamQuestionRelation';
            if(this[method]){
                this.form.loading = true;

                let axios = this[method](record);
                axios.then(response => {
                    this.onResponse(response.data, () => {
                        this.loadExam();
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    if(this.examQuestionRelationResponseMessages[error.response.data.message]) {
                        error.response.data.message = this.$t('pages.module.examManagement.exam.questionRelation.responseMessages.' + this.examQuestionRelationResponseMessages[error.response.data.message]);
                    }

                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        async addExamQuestionRelation(record){
            let examQuestionLength = this.exam.questions.length;
            let formData = {
                exam_id: this.examID,
                question_id: record.id,
                sort: examQuestionLength ? (this.exam.questions[(examQuestionLength - 1)].pivot.sort + 1) : 1
            };

            return this.axios.post(this.endpoints['module_exam_question_relation'], formData);
        },
        async removeExamQuestionRelation(formData){
            return this.axios.delete(this.endpoints['module_exam_question_relation'] + '/' + formData.pivot.id)
        },
        async updateExamQuestionRelation(formData){
            return this.axios.put(this.endpoints['module_exam_question_relation'] + '/' + formData.pivot.id, formData.pivot)
        },
        getCorrectAnswer(record) {
            let result = null;

            if(record.answers && record.answers.length && record.correct_answer_id){
                record.answers.sort((a,b) => {
                    return a.sort - b.sort;
                });

                let foundIndex = record.answers.findIndex((answer) => answer.id == record.correct_answer_id);

                if(foundIndex != -1){
                    result = {
                        foundIndex: foundIndex,
                        record: record.answers[foundIndex],
                    }
                }
            }

            return result;
        },
        showDetail(modalName, record) {
            this.showDetailData = record;

            if(modalName == 'correctAnswerModal'){
                this.showDetailData = this.getCorrectAnswer(record);
            }

            let modal = this.$refs[modalName];
            if(modal && this.showDetailData){
                this.showModal(modal);
            }
        },
        getRecord(type, record){
            if(this.questionTypes.length) {
                let recordTypeCode = this.resolveDatum(this.questionTypes, record.type_id, 'id').code;
                let splitted = recordTypeCode.split('_').map((part) => this.ucFirst(part));

                let methodName = 'getRecord' + splitted.join('');

                return this[methodName] ? this[methodName](type, record) : false
            }
        },
        getRecordNormal(type, record){
            let typeData = {
                answerCount: record.answers_count
            }

            return typeData[type] ?? null;
        },
        getRecordImage(type, record){
            let typeData = {
                answerCount: record.external_answer_count,
                correctAnswer: this.sprintf(
                    this.$t('pages.module.examManagement.question.answer.titlePattern'),
                    [this.$root.questionLetters[(record.correct_answer_id - 1)]]
                )
            }

            return typeData[type] ?? null;
        },
        getRecordTrueOrFalse(type, record){
            let typeData = {
                answerCount: 2,
                correctAnswer: this.$t('common.' + (record.correct_answer_id == 1 ? 'true' : 'false'))
            }

            return typeData[type] ?? null;
        },
        newCloneQuestion(){
            this.cloneQuestion.form.data = {
                target_id: parseInt(this.examID)
            };
            this.cloneQuestion.form.title = this.$t("pages.module.examManagement.exam.cloneQuestion.newCloneQuestion");
            this.showModal(this.$refs.cloneQuestionModal);
        },
        onSubmitCloneQuestion(){
            this.$refs.cloneQuestionForm.validate((valid) => {
                if(valid) {
                    this.cloneQuestion.form.loading = true;

                    this.axios.post(this.endpoints['module_exam_clone_question'], this.cloneQuestion.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.loadExam();
                            this.hideModal(this.$refs.cloneQuestionModal);
                        }, () => {
                            this.cloneQuestion.form.loading = false;
                        });
                    }).catch(error => {
                        if(this.cloneQuestion.responseMessages[error.response.data.message]) {
                            error.response.data.message = this.$t('pages.module.examManagement.exam.cloneQuestion.responseMessages.' + this.cloneQuestion.responseMessages[error.response.data.message]);
                        }
                        this.onResponseFailure(error.response.data, () => {
                            this.cloneQuestion.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        changedGain(){
            let gains = undefined;

            if(this.gainModel.length){
                gains = this.gainModel.join(',');
            }

            this.questionFilterData.gains = gains;
        }
    }
}
</script>

<style>
.table p {
    margin-bottom: 0;
}

.table img, .modal .detail-item img{
    width: 150px !important;
    height: 75px !important;
    object-fit: cover !important;
}
</style>